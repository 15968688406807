import { anyPass, defaultTo, equals, ifElse, is, isEmpty, isNil, map, not, pathOr, pipe, tap, when } from 'ramda'
import { waitForCondition } from '../../utils/helpers'
import FlatasticConfig from '../config'
import yepptCordova from './yptCordova'


const firebaseNotInstalledError = 'FirebasePlugin is not installed'

export default angular.module('flatastic.firebase', [
  yepptCordova,
  FlatasticConfig,
])

.run(['Firebase', '$rootScope', 'FlatasticEvents', function(Firebase, $rootScope, FlatasticEvents) {
  // Broadcast push-receive event
  Firebase.onNotificationOpen();
  Firebase.fetchAndActivateConfig()

  $rootScope.$on(FlatasticEvents.user.didLogout, function() {
    Firebase.setUserId(null).catch(console.error)
  })

  // Log Page Views
  $rootScope.$on('$stateChangeSuccess', function(_, toState) {
    Firebase.logScreenView(toState.url);
  });
  $rootScope.$on('track-pageview', function (_, viewName) {
    Firebase.logScreenView(viewName);
  });
  $rootScope.$on('track-event', function (_, { event, ...eventParams }) {
    if (!event) {
      return Firebase.logEvent('generic_event', eventParams);
    }
    Firebase.logEvent(event, eventParams);
  });
}])

.factory('Firebase',
    ['$window', '$q', 'yptCordova', '$rootScope',
    function($window, $q, yptCordova, $rootScope) {

  let fetchConfigPromise

  return {
    fetchConfig,
    activateConfig,
    fetchAndActivateConfig,
    getBadgeNumber,
    getConfigValue,
    getConfigValues,
    getInstanceId,
    getToken,
    getAPNSToken,
    grantPermission,
    hasPermission,
    logScreenView,
    logEvent,
    setBadgeNumber,
    onNotificationOpen,
    setUserProperty,
    setUserId,
    unregister,
  }

  //// Functions

  function hasPermission() {
    return getPlugin()
      .then(FirebasePlugin => new Promise((resolve) => FirebasePlugin.hasPermission(resolve)))
  }

  function grantPermission() {
    return getPlugin()
      .then(FirebasePlugin => new Promise((resolve, reject) =>
        FirebasePlugin.grantPermission(
          hasPermission => hasPermission ? resolve(hasPermission) : reject(hasPermission)
        )
      ))
  }

  function unregister() {
    return getPlugin()
      .then(FirebasePlugin => Promise.resolve(FirebasePlugin.unregister()))
  }

  function setBadgeNumber(count) {
    return getPlugin()
      .then(FirebasePlugin => Promise.resolve(FirebasePlugin.setBadgeNumber(count)))
  }

  function getBadgeNumber() {
    return getPlugin()
      .then(FirebasePlugin => new Promise(resolve => FirebasePlugin.getBadgeNumber(resolve)))
  }

  function logEvent(eventName, params) {
    console.debug('Firebase.logEvent', { eventName, params })
    return getPlugin()
      .then(function(FirebasePlugin) {
        var deferred = $q.defer();
        const eventParams = params ? map(truncate)(params) : {}
        FirebasePlugin.logEvent(eventName, eventParams, function(success) {
          deferred.resolve(success);
        }, function(error) {
          deferred.reject(error);
        });
        return deferred.promise;
      });
  }

  function truncate(value, maxLength = 100) {
    if (!is(String)(value)) { return value }
    value = value.trim()
    if (value.length <= maxLength) { return value }
    return `${value.slice(0, 99)}…`
  }

  function logScreenView(screenName) {
    return getPlugin()
      .then(function(FirebasePlugin) {
        var deferred = $q.defer();
        FirebasePlugin.setScreenName(screenName, function(response) {
          deferred.resolve(response);
        }, function(error) {
          deferred.reject(error);
        });
        return deferred.promise;
      });
  }

  function getPlugin() {
    return yptCordova.ready.then(function() {
      if (!$window.FirebasePlugin) {
        return $q.reject(firebaseNotInstalledError);
      }
      return $q.resolve($window.FirebasePlugin);
    });
  }

  function getConfigValues(keys) {
    return Promise.all(
      keys.map(([key, defaultValue]) => getConfigValue(key, defaultValue))
    )
  }

  function getConfigValue(key, defaultValue) {
    const parse = when(pipe(isEmpty, not), JSON.parse)
    return getPlugin()
    .then(function(FirebasePlugin) {
        var deferred = $q.defer();
        FirebasePlugin.getValue(
          key,
          value => {
            if (anyPass([isEmpty, isNil])(value)) {
              return deferred.resolve(defaultValue)
            }
            return deferred.resolve(defaultTo(defaultValue)(parse(value)))
          },
          deferred.reject
        );
        return deferred.promise;
      })
      .catch(error => {
        if (![
          'FirebasePlugin is not installed',
          `Did not get Cordova's "deviceready" event`,
          `window.cordova is not defined`,
        ].includes(error)) {
          console.error(error)
          throw error
        }
        return defaultValue
      });
  }

  function activateConfig() {
    return getPlugin()
      .then(function(FirebasePlugin) {
        var deferred = $q.defer();
        FirebasePlugin.activateFetched(
          deferred.resolve,
          deferred.reject
        );
        return deferred.promise;
      });
  }

  function getInstanceId() {
    return getPlugin().then(function(FirebasePlugin) {
      var deferred = $q.defer();
      FirebasePlugin.getId(deferred.resolve, deferred.reject)
      return deferred.promise
    })
  }


  function fetchConfig(cachingDurationInSecs) {
    if (fetchConfigPromise) {
      return fetchConfigPromise
    }
    // Waiting for instanceId
    // > Fixed a crash that could occur when attempting a remote config fetch before a valid Instance ID was available.
    // > https://github.com/firebase/firebase-ios-sdk/blob/master/FirebaseRemoteConfig/CHANGELOG.md#v447
    const isNotNull = pipe(equals(null), not)
    fetchConfigPromise = waitForCondition(getInstanceId, isNotNull, 5, 25)
      .then(getPlugin)
      .then(function(FirebasePlugin) {
        var deferred = $q.defer();
        const args = [cachingDurationInSecs, deferred.resolve, deferred.reject].filter(Boolean)
        FirebasePlugin.fetch.apply(null, args)
        return deferred.promise;
      })
      .then(tap(() => (fetchConfigPromise = undefined)))
    return fetchConfigPromise
  }

  function fetchAndActivateConfig() {
    return fetchConfig()
      .then(activateConfig)
  }

  function getToken() {
    return getPlugin()
      .then(FirebasePlugin => new Promise((resolve, reject) =>
        FirebasePlugin.getToken(ifElse(isNil, reject, resolve), reject)))
  }

  function getAPNSToken() {
    return getPlugin()
      .then(FirebasePlugin => new Promise((resolve, reject) =>
        FirebasePlugin.getAPNSToken(resolve, reject)))
  }

  function onNotificationOpen() {
    return getPlugin()
      .then(function(FirebasePlugin) {
        FirebasePlugin.onMessageReceived(function(notification) {
          console.debug('Firebase.onMessageReceive', notification)
          const apsAlert = pathOr({}, ['aps', 'alert'])(notification)
          const fcmOptions = pathOr({}, ['fcm_options'])(notification)
          let userdata
          try {
            userdata = JSON.parse(notification.u)
          } catch (e) {
            userdata = {}
          }
          notification = {
            ...fcmOptions,
            ...apsAlert,
            ...notification,
          }
          const userData = {
            userdata,
            title: notification.title,
            message: notification.body || notification.title,
            onStart: notification.tap,
            image: notification.image,
          }
          $rootScope.$broadcast('push-receive', userData);
        }, function(error) {
          console.error("Error getting the notification", error);
        });
      });
  }

  function setUserProperty(name, value) {
    return getPlugin()
      .then(FirebasePlugin =>
        new Promise((resolve, reject) =>
          FirebasePlugin.setUserProperty(name, ensureValueIsString(value), resolve, reject))
      )
  }

  function setUserId(userId) {
    return getPlugin()
      .then(FirebasePlugin => new Promise((resolve, reject) =>
        FirebasePlugin.setUserId(ensureValueIsString(userId), resolve, reject))
      )
  }

  function ensureValueIsString(value) {
    if (typeof value !== 'string') {
      return JSON.stringify(value)
    }
    return value
  }

}])

.name;
