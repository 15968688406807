import yepptCordova from './yptCordova'

export default angular.module('yeppt.camera', [
  yepptCordova,
])

.run(['yptCordova', 'yptCamera', function(yptCordova, yptCamera) {
  yptCordova.ready.then(function() {
    yptCamera.init();
  });
}])

.service('yptCamera', ['$q', '$document',
    function ($q, $document) {
  var destinationType,
      pictureSource;

  return {
    init,
    takePicture,
    savePicture,
  };

  ////

  function init() {
    if (navigator && navigator.camera) {
      destinationType = navigator.camera.DestinationType;
      pictureSource   = navigator.camera.PictureSourceType;
    } else {
      console.warn('Camera Plugin not installed');
    }
  };

  function saveImageToPhone(url, success, error) {
    var canvas, context, imageDataUrl, imageData;
    var img = new Image();
    img.onload = function() {
      canvas = $document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      context = canvas.getContext('2d');
      context.drawImage(img, 0, 0);
      try {
        imageDataUrl = canvas.toDataURL('image/jpeg', 1.0);
        imageData = imageDataUrl.replace(/data:image\/jpeg;base64,/, '');
        cordova.exec(
          success,
          error,
          'Canvas2ImagePlugin',
          'saveImageDataToLibrary',
          [imageData]
        );
      } catch (e) {
        error(e.message);
      }
    };
    try {
      img.src = url;
    } catch (e) {
      error(e.message);
    }
  };

  function savePicture(url) {

    // General stuff
    var deferred = $q.defer();
    var promise = deferred.promise;

    var onSuccess = function(data) {
      deferred.resolve(data);
    };
    var onError = function(data) {
      deferred.reject(data);
    };

    promise.success = function(fn) {
      promise.then(fn);
      return promise;
    };
    promise.error = function(fn) {
      promise.then(null, fn);
      return promise;
    };

    saveImageToPhone(url, onSuccess, onError);

    return promise;
  };

  function takePicture(settings) {

    if (!destinationType || !pictureSource) {
      init();
    }

    // General stuff
    var deferred = $q.defer();
    var promise = deferred.promise;
    var onSuccess = function(data) {
      deferred.resolve(data);
    };
    var onError = function(data) {
      deferred.reject(data);
    };
    promise.success = function(fn) {
      promise.then(fn);
      return promise;
    };
    promise.error = function(fn) {
      promise.then(null, fn);
      return promise;
    };

    if (!navigator && !navigator.camera) {
      onError('Camera Plugin not installed');
      return promise;
    }

    // Some default settings
    var mySettings = settings || {};
    mySettings.quality            = mySettings.quality || 80;
    mySettings.destinationType    = mySettings.destinationType ||
                                      destinationType.FILE_URI;
    mySettings.sourceType         = mySettings.sourceType ?
                                      mySettings.sourceType : 0;
    mySettings.targetWidth        = mySettings.targetWidth || 1920;
    mySettings.targetHeight       = mySettings.targetHeight || 1920;
    mySettings.correctOrientation = mySettings.correctOrientation || true;
    mySettings.saveToPhotoAlbum   = mySettings.saveToPhotoAlbum;
    mySettings.cameraDirection    = mySettings.cameraDirection || 0; // 0 = BACK

    navigator.camera.getPicture(onSuccess, onError, mySettings);

    return promise;

  };

}])

.name;
