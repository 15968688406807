import flatasticConfig from '../../scripts/config'
import ApiModule from '../../scripts/modules/api'
import { internalChoresEvents } from './svc_chores'

export default angular.module('flatastic.chores.service.chore', [
  ApiModule,
  flatasticConfig,
])

.factory('Chore',
    ['Api', '$rootScope', 'FlatasticEvents', '$timeout',
    function(Api, $rootScope, FlatasticEvents, $timeout) {

  function Chore(initObj) {
    this.newData = {};
    this.set(initObj);
  }

  Chore.prototype.set = function (data) {
    var self = this;
    for (var key in data) {
      // make sure that startDate is an instance of date
      if (key === 'startDate') {
        if (data['startDate'] instanceof Date === false) {
          if (typeof data['startDate'] === 'number') {
            data['startDate'] *= 1000;
          }
          data['startDate'] = new Date(data['startDate']);
        }
      }
      self[key] = data[key];
    }
    self.doUntil = self.lastDoneDate ?
      new Date((self.lastDoneDate + self.rotationTime) * 1000) :
      self.startDate;
    if (self.startDate && !self.lastDoneDate) {
      self.timeLeftNext = self.startDate.getTime() - new Date().getTime();
      self.timeLeftNext /= 1000;
    }
    self.timeLeftNext = Math.floor(self.timeLeftNext / (24 * 60 * 60));

    // make sure user ids are integers
    if (self.users) {
      self.users = self.users.map(function(userId) {
        return parseInt(userId, 10);
      });
    }

    return self;
  };

  Chore.prototype.toCollection = function() {
    var self = this;
    $rootScope.$broadcast(internalChoresEvents.addToCollection, self)
    $rootScope.$broadcast(FlatasticEvents.badge.update);
    return self;
  };

  Chore.prototype.removeFromCollection = function() {
    var self = this;
    $rootScope.$broadcast(internalChoresEvents.removeFromCollection, self)
    $rootScope.$broadcast(FlatasticEvents.badge.update);
    return self;
  };

  Chore.prototype.rawData = function() {
    var self = this;
    self.startDate = self.startDate || new Date();
    return {
      id: self.id,
      title: self.title,
      rotationTime: self.rotationTime,
      startDate: parseInt(self.startDate.getTime() / 1000, 10),
      users: self.users,
      fixed: self.fixed,
      points: self.points,
    };
  };

  Chore.prototype.remind = function() {
    var self = this;
    self.status = 'reminding';
    return Api.get('/chores/remind', { params: { id: self.id } })
      .success(function(data) {
        delete self.status;
        delete self.errorMessage;
      })
      .error(function(data) {
        self.status = 'error';
        self.errorMessage = data;
      });
  };

  /* Chore.next() */
  Chore.prototype.next = function(userId, completedBy) {
    var self = this;
    var usersBackup = angular.copy(self.users);
    self.status = 'next';
    if (self.rotationTime === -2) {
      // It's a one time chore
      $timeout(2500).then(() => self.removeFromCollection())
    } else if (!userId) {
      self.users.rotate(1);
    } else {
      var pos = self.users.indexOf(userId);
      if (pos < 0) {
        return console.log('userId does not exist');
      }
      var temp = self.users.splice(0, pos);
      self.users = self.users.concat(temp);
    }

    self.currentUser = self.users[0];
    $rootScope.$broadcast(FlatasticEvents.chores.willComplete);
    return Api.get('/chores/next', {
      params: {
        id: self.id,
        userId,
        completedBy,
      },
    })
      .success(function(data) {
        delete self.status;
        delete self.errorMessage;
        $rootScope.$broadcast(FlatasticEvents.chores.didCompleteChore, self);
        self.set(data);
      })
      .error(function(data) {
        self.status = 'error';
        self.errorMessage = data;
        self.users = usersBackup;
      });
  };

  /* Chore.delete() */
  Chore.prototype.delete = function() {
    var self = this;
    self.removeFromCollection()
    self.status = 'deleting';
    $rootScope.$broadcast(FlatasticEvents.badge.update);
    return Api.delete('/chores/id/' + self.id)
      .success(function(data) {
        delete self.status;
        delete self.errorMessage;
        $rootScope.$broadcast(FlatasticEvents.chores.didDeleteChore, self.id);
        $rootScope.$broadcast(FlatasticEvents.badge.update, self)
      })
      .error(function(data) {
        self.status = 'error';
        self.errorMessage = data;
        self.toCollection()
        $rootScope.$broadcast(FlatasticEvents.badge.update, self)
      })
  };

  /* Chore.save() */
  Chore.prototype.create = function() {
    var self = this;
    if (!self.local) {
      throw new Error('Cannot create an existing chore.');
    }
    self.status = 'saving';
    self.set(self.newData);
    $rootScope.$broadcast(FlatasticEvents.chores.willCreate);
    return Api.post('/chores', self.rawData())
      .success(function(data) {
        delete self.status;
        delete self.errorMessage;
        delete self.local;
        delete self.startDate;
        self.newData = {};
        self.set(data);
        $rootScope.$broadcast(FlatasticEvents.chores.didCreateChore, self.id);
        $rootScope.$broadcast(FlatasticEvents.badge.update);
      })
      .error(function(data) {
        self.status = 'error';
        self.errorMessage = data;
      });
  };

  /* Chore.update() */
  Chore.prototype.update = function() {
    var self = this;
    self.status = 'updating';
    self.set(self.newData);
    return Api.post('/chores/update', self.rawData())
      .success(function(data) {
        delete self.status;
        delete self.errorMessage;
        self.newData = {};
        self.set(data);
        $rootScope.$broadcast(FlatasticEvents.chores.update, self);
        $rootScope.$broadcast(FlatasticEvents.badge.update);
      })
      .error(function(data) {
        self.status = 'error';
        self.errorMessage = data;
      });
  };

  Chore.prototype.needsToBeDone = function(userId, withinDays) {
    var self = this;
    if (!userId || !withinDays) {
      console.error('Parameters "userId" and "withinDays" must be set');
    }
    var withinDays = withinDays || 3;
    return (self.currentUser == userId && (self.timeLeftNext <= withinDays));
  };

  Chore.prototype.canBeReminded = function(userId, daysLate) {
    var self = this;
    var daysLate = daysLate || 1;
    return (self.currentUser != userId && (self.timeLeftNext <= -daysLate));
  };

  return Chore;
}])

.name;
