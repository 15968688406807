import CordovaModule from './yptCordova'

export default angular.module('mcm.toast', [
  'ionic',
  CordovaModule,
])

.run(['mcmToast', '$ionicPlatform', function(mcmToast, $ionicPlatform) {
  $ionicPlatform.ready().then(function() {
      mcmToast.init();
  });
}])

.service('mcmToast',
    ['$q', '$rootScope', '$window', '$ionicLoading', '$timeout', 'yptCordova',
    function($q, $rootScope, $window, $ionicLoading, $timeout, yptCordova) {

  var plugin;
  var defaultOptions = {
    message: 'Flatastic is Awesome',
    duration: 'short',
    position: 'bottom',
    addPixelsY: -50,
    // styling: {
    //   opacity: 0.9, // 0.0 (transparent) to 1.0 (opaque). Default 0.8
    //   backgroundColor: '#21A358', // make sure you use #RRGGBB. Default #333333
    //   textColor: '#FFFFFF', // Ditto. Default #FFFFFF
    //   cornerRadius: 100, // minimum is 0 (square). iOS default 20, Android default 100
    //   horizontalPadding: 50, // iOS default 16, Android default 50
    //   verticalPadding: 30 // iOS default 12, Android default 30
    // }
  };

  return {
    init,
    show,
  };


  ////////////////////////////////


  function init() {
    if (!$window.plugins || !$window.plugins.toast) {
      console.debug('The Toast plugin is not installed. Will fall back to ' +
          '$ionicLoading');
      return;
    }
    plugin = $window.plugins.toast;
  };

  function getFallback(touchedFunction) {
    var $scope = $rootScope.$new(true);
    $scope.touchedFunction = touchedFunction;
    return {
      showWithOptions: function(options) {
        var duration  = options.duration === 'short' ? 3000 : 5000;
        $ionicLoading.show({
          template: `<div ng-click="touchedFunction()" style="max-width: 80vw;">${options.message}</div>`,
          noBackdrop: true,
          duration: duration,
          scope: $scope,
        });
        $timeout(function() {
          $scope.$destroy();
        }, duration);
      },
    };
  }

  function show(options, touchedFunction) {
    return yptCordova.ready.then(function() {
      // General stuff
      var deferred = $q.defer();
      var promise = deferred.promise;
      var onSuccess = function(result) {
        // User has clicked on the successfully shown toaster
        if (result && result.event && result.event === 'touch' && typeof touchedFunction === 'function') {
          touchedFunction(result.data);
        }
        deferred.resolve(result);
      };
      var onError = function(data) {
        deferred.reject(data);
      };

      if (!plugin) {
        plugin = getFallback(touchedFunction);
      }
      options = angular.merge(defaultOptions, options);
      plugin.showWithOptions(options, onSuccess, onError);

      return promise;
    });
  }

}])

.name;
