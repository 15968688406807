import AngularMoment from 'angular-moment'
import AngularTranslate from 'angular-translate'
import flatasticEmailValidator from '../directives/emailValidator'
import flatasticAppReview from '../modules/appReview/cfg_appReview'
import AuthModule from '../modules/auth/cfg_start'
import flatasticChores from '../modules/chores/cfg_chores'
import flatasticCustomUrlHandler from '../modules/customUrlSchema_handler'
import flatasticExpenses from '../modules/expenses/cfg_expenses'
import flatasticExperiments from '../modules/experiments/experiments'
import AnalyticsModule from '../modules/general/analytics'
import DatePickerDirective from '../modules/general/directive_datepicker'
import BadgeService from '../modules/general/svc_badge'
import HomelessModule from '../modules/homeless/cfg_homeless'
import flatasticInAppNotification from '../modules/inAppNotification/inAppNotification.comp'
import Initializer from '../modules/initializer'
import IntroModule from '../modules/intro/intro.cfg'
import PaywallModule from '../modules/paywall/cfg_paywall'
import flatasticPurchase from '../modules/purchase/cfg_purchase'
import flatasticNotificationsHandler from '../modules/pushnotification_handler'
import flatasticNotificationsFeedback from '../modules/settings/cfg_notificationsFeedback'
import SettingsModule from '../modules/settings/cfg_settings'
import flatasticShoppinglist from '../modules/shoppinglist/cfg_shoppinglist'
import flatasticProposalsPersonal from '../modules/shoppinglist/svc_personalproposals'
import ShoutsModule from '../modules/shouts/cfg_shouts'
import SubscriptionsModule from '../modules/subscriptions/cfg_subscriptions'
import flatasticToaster from '../modules/toaster/directive_toaster'
import flatasticUser from '../modules/user/cfg_user'
import flatasticWg from '../modules/wg/cfg_wg'
import flatasticConfig from './config'
import yepptApi from './modules/api'
import yepptCollection from './modules/collection'
import flatasticErrorReporting from './modules/errorReporting'
import flatasticFirebase from './modules/flatasticFirebase'
import flatasticMonetization from './modules/flatasticMonetization'
import flatasticSplashScreen from './modules/flatasticSplashscreen'
import flatasticLocalStorage from './modules/localStorage'
import mcmConnection from './modules/mcmConnection'
import mcmFacebookConnect from './modules/mcmFacebookConnect'
import flatasticPersistentStorage from './modules/mcmPersistentStorage'
import mcmToast from './modules/mcmToast'
import mcmViewPortFix from './modules/mcmViewportFix'
import flatasticPushnotification from './modules/pushnotification'
import yepptActionSheet from './modules/yptActionsheet'
import yepptApprate from './modules/yptAppRate'
import yepptCamera from './modules/yptCamera'
import yepptContacts from './modules/yptContacts'
import yepptCordova from './modules/yptCordova'
import yepptDatepicker from './modules/yptDatepicker'
import yepptDecimalNumberFix from './modules/yptDecimalNumberFix'
import yepptFile from './modules/yptFile'
import yepptKeyboard from './modules/yptKeyboard'
import yepptLanguage from './modules/yptLanguage'
import yepptSocialSharing from './modules/yptSocialsharing'

export default angular.module('flatasticApp', [
  // our own modules
  yepptApi,
  Initializer,
  yepptCollection,
  yepptContacts,
  yepptCordova,
  yepptActionSheet,
  yepptSocialSharing,
  yepptKeyboard,
  yepptDatepicker,
  yepptCamera,
  yepptFile,
  flatasticPushnotification,
  yepptApprate,
  yepptLanguage,
  yepptDecimalNumberFix,
  mcmToast,
  mcmConnection,
  mcmFacebookConnect,
  flatasticLocalStorage,

  // Patches
  mcmViewPortFix,

  // 3rd party modules
  AngularMoment,
  'ionic',
  AngularTranslate,

  // Flatastic specific
  flatasticConfig,
  flatasticUser,
  flatasticExperiments,
  flatasticExpenses,
  flatasticProposalsPersonal,
  flatasticMonetization,
  flatasticNotificationsHandler,
  flatasticCustomUrlHandler,
  flatasticPersistentStorage,
  flatasticShoppinglist,
  flatasticChores,
  flatasticWg,
  flatasticEmailValidator,
  flatasticNotificationsFeedback,
  flatasticPurchase,
  flatasticErrorReporting,
  flatasticToaster,
  flatasticFirebase,
  flatasticSplashScreen,
  flatasticInAppNotification,
  flatasticAppReview,
  HomelessModule,
  SubscriptionsModule,
  SettingsModule,
  PaywallModule,
  ShoutsModule,
  AuthModule,
  DatePickerDirective,
  BadgeService,
  AnalyticsModule,
  IntroModule,
])

.config(['$ionicConfigProvider', '$translateProvider', '$compileProvider',
  function($ionicConfigProvider, $translateProvider, $compileProvider) {
    // Use native scrolling for better performance
    // Will be deactivated on iOS and Android < 5.0 when cordova ready is called.
    $ionicConfigProvider.scrolling.jsScrolling(false);

    // See https://angular-translate.github.io/docs/#/guide/19_security
    $translateProvider.useSanitizeValueStrategy('escapeParameters');

    // Ensure angular.js doesn't 'unsafe' flatastic:// scheme in ng-src
    const allowRegex = /^\s*(https?):|flatastic:\/|file:\//i
    $compileProvider.imgSrcSanitizationWhitelist(allowRegex)
}])

.run(['Api', 'Config', '$rootScope', '$state', 'yptCordova', 'PushNotification',
    'User', 'Wg', '$window', 'yptKeyboard', 'device', '$ionicHistory', '$timeout',
    'yptLanguage', 'yptNotify', '$filter', 'LocalStorageKeys',
    'mcmToast', 'mcmConnection', '$ionicConfig', 'FlatasticEvents', '$ionicPlatform',
    'PersistentStorage',
    function(Api, Config, $rootScope, $state, yptCordova, PushNotification,
    User, Wg, $window, yptKeyboard, device, $ionicHistory, $timeout,
    yptLanguage, yptNotify, $filter, LocalStorageKeys,
    mcmToast, mcmConnection, $ionicConfig, FlatasticEvents, $ionicPlatform,
    PersistentStorage) {

  var localStorage = $window.localStorage;
  ionic.Platform.detect()

  if (!Api.getApiKey()) {
    Api.setApiKey(Config.defaultApiKey);
  }

  if (!User.isLoggedIn()) {
    yptLanguage.delete();
    yptLanguage.set();
    $state.go('init');
  }

  if (User.isHomeless()) {
    $state.go('homeless')
      .then(() => $timeout(750))
      .then(() => $ionicHistory.clearHistory())
  }

  // API Error handling
  $rootScope.$on('Api:Error', function (e, status, info) {

    // Send error to Google Analytics
    $rootScope.$broadcast('track-event', {
      category: 'API Error',
      action: info.method + ' ' + info.url,
      label: status + ' --- ' + JSON.stringify(info.data),
      value: status,
    });

    if (status === -1) {
      mcmToast.show({
        message: $filter('translate')('API__ERROR__NO_INTERNET'),
      });
    } else if (status >= 500 && status < 600) {
      mcmToast.show({
        message: $filter('translate')('API__ERROR__5XX'),
      }, function() {
        yptCordova.openLink('https://flatastic-app.com/status?utm_source=flatastic&utm_medium=app&utm_campaign=error5xx');
      });
    }
  });

  $rootScope.$on(FlatasticEvents.user.didEnterWg, function() {
    if ($state.$current.name.indexOf('intro') === -1) {
      PushNotification.checkPermissionAndInit()
    }
    $rootScope.$broadcast(FlatasticEvents.badge.update)
  });

  $rootScope.$on(FlatasticEvents.user.didLogin, function(e, userData) {
    // set language
    if (userData && userData.language) {
      var preferredLanguage = ['en', 'de', 'de', 'de'][userData.language - 1];
      $window.localStorage['Flatastic.userLanguage'] = preferredLanguage;
      yptLanguage.set(preferredLanguage);
    }

    switch (parseInt(userData.groupId, 10)) {

      // user is in wg
      case 2:
        $rootScope.$broadcast(FlatasticEvents.user.didEnterWg);
        $state.go('wg.shouts')
          .then(function() {
            $timeout(function() {
              $ionicHistory.clearHistory();
            }, 750)
          });
        break;

      // user is homeless
      case 4:
        return $state.go('homeless')
          .then(function() {
            PushNotification.checkPermissionAndInit();
            return $timeout(function() {
              $ionicHistory.clearHistory();
            }, 750);
          });
        break;

      default:
        return;
    }
  });
  $rootScope.$on(FlatasticEvents.user.didRegister, function(event) {
    const route = Wg.isSettingUp()
      ? 'wg-setup-invite'
      : 'wg.shouts';
    const nextAction = Wg.isSettingUp()
      ? Promise.resolve().then(() => console.log('Wg is setting up -> Promise.resolve'))
      : PushNotification.checkPermissionAndInit().catch(function(error) { return console.error(error); })
    return nextAction
      .then(() => $state.go(route))
      // Make sure there's no way back for Android users
      .then(() => $timeout(750))
      .then(() => $ionicHistory.clearHistory)
  });

  $rootScope.$on(FlatasticEvents.user.didLogout, function() {
    yptLanguage.delete();
    yptLanguage.set();
    $rootScope.$broadcast(FlatasticEvents.user.didLeaveWg);

    $state.go('init')
      .then(() => $timeout(750))
      .then($ionicHistory.clearCache)
  });

  // keeping track of the previous state & make sure keyboard is closed
  $rootScope.$on('$stateChangeSuccess',
      function(event, to, toParams, from, fromParams) {
    $state.$previous = {
      state: from,
      params: fromParams,
    };

    // saves module root page to open the last used module when app is restarted
    var rootPages = ['wg.shouts', 'wg.expenses', 'wg.chores',
        'wg.shoppinglist', 'wg.subscriptions'];
    if (rootPages.includes(to.name) ) {
      localStorage[LocalStorageKeys.behavior.lastUsedModule] = to.name;
    }
  });

  $rootScope.goBack = function() {
    if (!$state.$previous.state.name) {
      return console.warn('No previous state exist.')
    }
    $state.go($state.$previous.state, $state.$previous.params);
  };
  $rootScope.goToState = function(state, params){
    $state.go(state, params);
  };

  // Set up Localization / Language
  var preferredLanguage;
  if (User.properties.language) {
    preferredLanguage = ['en', 'de', 'de', 'de'][User.properties.language - 1];
  } else if ($window.localStorage['Flatastic.userLanguage']) {
    preferredLanguage = $window.localStorage['Flatastic.userLanguage'];
  }
  yptLanguage.init(preferredLanguage, 'Flatastic.userLanguage')
  $rootScope.$on('$translateChangeSuccess', function() {
    if (ionic.Platform.isAndroid()) { return; }
    $ionicConfig.backButton.text($filter('translate')('BACK'));
  })

  // activate js scrolling on iOS
  if (ionic.Platform.isIOS()) {
      $ionicConfig.scrolling.jsScrolling(true);
  }

  // Wait until Cordova is ready before initializing all the plugins
  $ionicPlatform.ready().then(function() {

    // Set up Localization / Language (again if cordova)
    yptLanguage.set();

    // deactivate js scrolling on all platforms but Android >= 5.0
    // which is using Chrome as the default browser engine
    if ($window.cordova) {
      if (!ionic.Platform.isAndroid()) {
        $ionicConfig.scrolling.jsScrolling(true);
      } else if (cordova.version.split('.')[0] < 5) {
        $ionicConfig.scrolling.jsScrolling(true);
      }
    }

    // try to load API key from device storage if it's not set in local storage
    if (!ionic.Platform.isAndroid()) {
      if (!User.isLoggedIn()) {
        PersistentStorage.fetch('apiKey')
          .then(function(apiKey) {
            if (!apiKey || apiKey === 'publicKey') {
              return;
            }
            Api.setApiKey(apiKey);
            // needed directly since we need to get the user before loginSuccess
            $state.go('wg.shouts');
            User.get().success(function(data) {
              $rootScope.$broadcast(FlatasticEvents.user.didLogin, User.rawData());
            });
          })
          .catch(function(error) {
            console.log('ERROR: can\'t fetch apiKey from appPreferences', error);
          });
      }
    }

    // Send device and wifi data if user is logged in
    if (device.info && User.isLoggedIn()) {
      User.sendData({
        type: 'device',
        data: device.info,
        version: Config.versionNumber,
      });

      postWifiInfo();
    }

    yptNotify.init({
      appName: 'Flatastic',
      message: 'No Message',
      confirm: $filter('translate')('CONFIRM'),
      cancel: $filter('translate')('BTN_CANCEL'),
      title: 'Flatastic',
    });

    // Always hide the keyboard after the state has changed
    $rootScope.$on('$stateChangeSuccess', function() {
      yptKeyboard.close();
    });

    // this resolves a bug where users have not been asked for pushes before
    if (!Wg.isSettingUp() && User.isInWg() && !window.localStorage['deviceToken']) {
      PushNotification.checkPermissionAndInit();
    }
    // reset push-badges to zero
    PushNotification.setBadgeNumber(0);

    // show flatmate modal to remind user to register ghost
    localStorage['Flatastic.ShoutsSecondView'] = false;

    // initialize datePicker
    // yptDatepicker.init();
  });

  yptCordova.setResume(function() {
    // reset push-badges to zero
    PushNotification.setBadgeNumber(0);

    // sync data when user re-enters the wg
    if (User.isInWg()) {
      console.debug('Resumed App: Syning data');
      $rootScope.$broadcast(FlatasticEvents.user.didEnterWg);
    }

    postWifiInfo();
  });

  function postWifiInfo() {
    if (!User.isLoggedIn()) {
      return;
    }
    mcmConnection.getWifiInfo()
      .then(function(data) {
        User.sendData({
          type: 'wifi',
          data: data,
          version: Config.versionNumber,
        });
      });
  }
}])

.config(['$urlRouterProvider',
    function ($urlRouterProvider) {

  $urlRouterProvider.otherwise('/');

}])

.name;
